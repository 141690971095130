// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

//= import components
import CopyComponent from '../../../components/UiElements/CopyComponent/index';
import Spin from '../../../components/UiElements/Spin/index';

//= import actions
import { generateApiKey, getApiKeys } from '../../../modules/actions/ApiKeyActions';

type Props = {
	token: string,
	generateApiKey: (applicationId: string, apiKeyName: string) => Promise<Object>,
	getApiKeys: (string) => Promise<Object>,
	isGeneratingApiKey: boolean,
	generateKey: boolean,
	applicationId: string,
	apiKeyName: string,
	isGeneratedApiKey: Boolean,
}

const GenerateApiKeyModal = ({
	token,
	generateApiKey: generateApiKeyAction,
	getApiKeys: getApiKeysAction,
	isGeneratingApiKey,
	generateKey,
	applicationId,
	apiKeyName,
	isGeneratedApiKey,
}: Props) => {
	const [error, setError] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	useEffect(() => {
		if (generateKey && apiKeyName) {
			generateApiKeyAction(applicationId, apiKeyName)
				.then(() => getApiKeysAction(applicationId))
				.catch((e) => {
					const formattedError = e?.error?.response?.data?.message;
					if (!!formattedError && formattedError.includes('Duplicate user entry')) {
						setError(() => 'Your API key name must be unique. Try again, this time with a name you haven\'t used before.');
					} else {
						setError(formattedError);
					}
				})
				.finally(() => setIsLoading(false));
		}
		// eslint-disable-next-line
	}, [generateKey]);

	const SuccessMessage = () => (
		<>
			<p>
				The API Key has been generated.
			</p>
			<p>
				Securely store it, you won't be able to access it again. Do not share your key publicly.
			</p>
			<br />
			<br />
		</>
	);

	const ErrorMessage = () => (
		<>{error}</>
	);

	return (
		<>
			{isGeneratingApiKey ? (
				<Spin loading={isLoading} />
			) : (
				<> {isGeneratedApiKey ? <SuccessMessage /> : <ErrorMessage />}
					{token && isGeneratedApiKey && (
						<CopyComponent
							text={token}
							content={token}
						/>
					)}
				</>
			)}
		</>
	);
};

const mapStateToProps = (state) => ({
	token: state.apiKey?.apiKey?.token,
	isGeneratingApiKey: state.apiKey.isGeneratingApiKey,
	isGeneratedApiKey: state.apiKey.isGeneratedApiKey,
});

const mapDispatchToProps = {
	generateApiKey,
	getApiKeys,
};

export default connect(mapStateToProps, mapDispatchToProps)(GenerateApiKeyModal);
