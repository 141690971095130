// @flow

const initialState = {
	customerAccounts: [],
	isFetchingCustomerAccounts: false,
	customerAccountsTotal: 0,
	isFetchingClientAccounts: false,
	clientAccounts: [],
	clientAccountsTotal: 0,
	userAccounts: [],
	isFetchingUserAccounts: false,
	userAccountsTotal: 0,
	accountAccounts: [],
	isFetchingAccountAccounts: false,
	accountAccountsTotal: 0,
	bankAccount: {},
	isFetchingBankAccount: false,
	isFetchingBankAccountPayments: false,
	bankAccountPayments: [],
	bankAccountPaymentsTotal: 0,
	isFetchingBankApplicationPayments: false,
	bankApplicationPayments: [],
	bankApplicationPaymentsTotal: 0,
	isFetchingBankPayment: false,
	bankPayment: {},
};

export type AccountDetailsType = Array<{
	scheme: string,
	details: {
		iban: string,
		bic: string,
		sortCode: string,
		accountNumber: string,
		accountHolderAddress: {
			addressLine1: string,
			addressLine2: string,
			country: string,
			postCode: string,
			postTown: string,
			streetName: string,
			streetNumber: string,
		},
		accountHolderName: string,
	}
}>
export type BankAccountType = {
	id: string,
    externalId: string,
    instrumentId: string,
	accountDetails: AccountDetailsType,
    name: string,
    description: string,
    type: string,
    userBankAccount: {
      walletId: string,
      address: string,
      accountId: string,
      bankUser: {
        id: string,
        userId: string,
        externalId: string,
      }
    },
    customerBankAccount: {
      isCollection: boolean,
      isPayOut: boolean,
    },
    application: {
      id: string,
      customerId: string,
    },
    provider: {
      id: string,
      name: string,
    },
    createdAt: string,
	updatedAt: string,
	balance: string,
}
export type PaymentType = {
	id: string,
    amount: string,
    instrumentId: string,
    status: string,
    direction: string,
    counterparty: {
		verified: boolean,
		details: {
			reference: string,
			bankAccountId: string,
			accountHolderName: string,
		},
		key: string,
		scheme: string,
	},
	scheme: string,
    bankAccountId: string,
    bankUserId: string,
	createdAt: string,
	updatedAt: string,
    applicationId: string,
	message: string,
	bankTransfers: Array<Object>,
	request: {
		amount: string,
		destination: {
			accountHolderAddress: {
				addressLine1: string,
				addressLine2: string,
				country: string,
				postCode: string,
				postTown: string,
			},
			accountHolderName: string,
			iban: string,
			bic: string,
			reference: string,
		},
		message: string,
		provider: string,
	}
}
export type BankState = {
	customerAccounts: Array<BankAccountType>,
	isFetchingCustomerAccounts: boolean,
	customerAccountsTotal: number,
	isFetchingClientAccounts: boolean,
	clientAccounts: Array<BankAccountType>,
	clientAccountsTotal: number,
	userAccounts: Array<BankAccountType>,
	isFetchingUserAccounts: boolean,
	userAccountsTotal: number,
	accountAccounts: Array<BankAccountType>,
	isFetchingAccountAccounts: boolean,
	accountAccountsTotal: number,
	bankAccount: BankAccountType,
	isFetchingBankAccount: boolean,
	isFetchingBankAccountPayments: boolean,
	bankAccountPayments: Array<PaymentType>,
	bankAccountPaymentsTotal: number,
	isFetchingBankApplicationPayments: boolean,
	bankApplicationPayments: Array<PaymentType>,
	bankApplicationPaymentsTotal: number,
	isFetchingBankPayment: boolean,
	bankPayment: PaymentType,
}

type CustomerAccountsAction = {
	type:
	| 'GET_CUSTOMER_BANK_ACCOUNTS'
	| 'GET_CUSTOMER_BANK_ACCOUNTS_FAIL'
	| 'GET_CUSTOMER_BANK_ACCOUNTS_SUCCESS'
	| 'GET_CLIENT_BANK_ACCOUNTS'
	| 'GET_CLIENT_BANK_ACCOUNTS_SUCCESS'
	| 'GET_CLIENT_BANK_ACCOUNTS_FAIL'
	| 'GET_USER_BANK_ACCOUNTS'
	| 'GET_USER_BANK_ACCOUNTS_SUCCESS'
	| 'GET_USER_BANK_ACCOUNTS_FAIL'
	| 'GET_ACCOUNT_BANK_ACCOUNTS'
	| 'GET_ACCOUNT_BANK_ACCOUNTS_SUCCESS'
	| 'GET_ACCOUNT_BANK_ACCOUNTS_FAIL',
	payload: {
		data: Array<BankAccountType>,
		headers: {
			'total-count': number,
		}
	},
	error: {
		code: number,
        message: string,
    }
}
type CustomerAccountAction = {
	type:
	| 'GET_CUSTOMER_BANK_ACCOUNT'
	| 'GET_CUSTOMER_BANK_ACCOUNT_FAIL'
	| 'GET_CUSTOMER_BANK_ACCOUNT_SUCCESS'
	| 'ASSOCIATE_CUSTOMER_BANK_ACCOUNT_SUCCESS'
	| 'EDIT_CUSTOMER_BANK_ACCOUNT_SUCCESS'
	| 'GET_CLIENT_BANK_ACCOUNT'
	| 'GET_CLIENT_BANK_ACCOUNT_SUCCESS'
	| 'GET_CLIENT_BANK_ACCOUNT_FAIL'
	| 'CREATE_CLIENT_BANK_ACCOUNT_SUCCESS',
	payload: {
		data: BankAccountType,
	},
	error: {
		code: number,
		message: string,
	}
}
type CustomerPaymentsAction = {
	type:
	| 'GET_BANK_ACCOUNT_PAYMENTS'
	| 'GET_BANK_ACCOUNT_PAYMENTS_SUCCESS'
	| 'GET_BANK_ACCOUNT_PAYMENTS_FAIL'
	| 'GET_APPLICATION_BANK_PAYMENTS'
	| 'GET_APPLICATION_BANK_PAYMENTS_SUCCESS'
	| 'GET_APPLICATION_BANK_PAYMENTS_FAIL',
	payload: {
		data: Array<PaymentType>,
		headers: {
			'total-count': number,
			'x-count': number,
		}
	},
	error: {
		code: number,
        message: string,
    }
}
type PaymentAction = {
	type:
	| 'GET_BANK_PAYMENT'
	| 'GET_BANK_PAYMENT_SUCCESS'
	| 'GET_BANK_PAYMENT_FAIL'
	| 'CUSTOMER_PAYOUT'
	| 'CUSTOMER_PAYOUT_SUCCESS',
	payload: {
		data: PaymentType,
	},
	error: {
		code: number,
        message: string,
    }
}

type Action = CustomerAccountsAction
| CustomerAccountAction
| CustomerPaymentsAction
| PaymentAction;

export default function bankReducer(
	state: BankState = initialState,
	action: Action,
): BankState {
	switch (action.type) {
	case 'GET_CUSTOMER_BANK_ACCOUNTS':
		return {
			...state,
			isFetchingCustomerAccounts: true,
			customerAccounts: [],
			customerAccountsTotal: 0,
		};
	case 'GET_CUSTOMER_BANK_ACCOUNTS_FAIL':
		return {
			...state,
			isFetchingCustomerAccounts: false,
		};
	case 'GET_CUSTOMER_BANK_ACCOUNTS_SUCCESS':
		return {
			...state,
			isFetchingCustomerAccounts: false,
			customerAccounts: action.payload.data,
			customerAccountsTotal: parseInt(action.payload.headers['total-count'], 10),
		};
	case 'GET_CUSTOMER_BANK_ACCOUNT':
	case 'GET_CLIENT_BANK_ACCOUNT':
		return {
			...state,
			isFetchingBankAccount: true,
			bankAccount: {},
		};
	case 'GET_CUSTOMER_BANK_ACCOUNT_FAIL':
	case 'GET_CLIENT_BANK_ACCOUNT_FAIL':
		return {
			...state,
			isFetchingBankAccount: false,
		};
	case 'GET_CUSTOMER_BANK_ACCOUNT_SUCCESS':
	case 'GET_CLIENT_BANK_ACCOUNT_SUCCESS':
	case 'EDIT_CUSTOMER_BANK_ACCOUNT_SUCCESS':
		return {
			...state,
			isFetchingBankAccount: false,
			bankAccount: action.payload.data,
		};
	case 'ASSOCIATE_CUSTOMER_BANK_ACCOUNT_SUCCESS':
		return {
			...state,
			customerAccounts: [...state.customerAccounts, action.payload.data],
			customerAccountsTotal: state.customerAccountsTotal + 1,
		};
	case 'GET_BANK_ACCOUNT_PAYMENTS':
		return {
			...state,
			isFetchingBankAccountPayments: true,
			bankAccountPayments: [],
		};
	case 'GET_BANK_ACCOUNT_PAYMENTS_SUCCESS':
		return {
			...state,
			isFetchingBankAccountPayments: false,
			bankAccountPayments: action.payload.data,
			bankAccountPaymentsTotal: parseInt(action.payload.headers['total-count'], 10),
		};
	case 'GET_BANK_ACCOUNT_PAYMENTS_FAIL':
		return {
			...state,
			isFetchingBankAccountPayments: false,
			bankAccountPayments: [],
		};
	case 'CUSTOMER_PAYOUT_SUCCESS':
		return {
			...state,
			bankAccountPayments: [...state.bankAccountPayments, action.payload.data],
			bankAccountPaymentsTotal: state.bankAccountPaymentsTotal + 1,
		};
	case 'GET_APPLICATION_BANK_PAYMENTS':
		return {
			...state,
			isFetchingBankApplicationPayments: true,
			bankApplicationPayments: [],
		};
	case 'GET_APPLICATION_BANK_PAYMENTS_SUCCESS':
		return {
			...state,
			isFetchingBankApplicationPayments: false,
			bankApplicationPayments: action.payload.data,
			bankApplicationPaymentsTotal: action.payload.headers['x-count']
				? parseInt(action.payload.headers['x-count'], 10)
				: action.payload.data.length,
		};
	case 'GET_APPLICATION_BANK_PAYMENTS_FAIL':
		return {
			...state,
			isFetchingBankApplicationPayments: false,
			error: action.error,
		};
	case 'GET_CLIENT_BANK_ACCOUNTS':
		return {
			...state,
			isFetchingClientAccounts: true,
			clientAccounts: [],
			clientAccountsTotal: 0,
		};
	case 'GET_CLIENT_BANK_ACCOUNTS_FAIL':
		return {
			...state,
			isFetchingClientAccounts: false,
		};
	case 'GET_CLIENT_BANK_ACCOUNTS_SUCCESS':
		return {
			...state,
			isFetchingClientAccounts: false,
			clientAccounts: action.payload.data,
			clientAccountsTotal: parseInt(action.payload.headers['x-count'], 10),
		};
	case 'GET_USER_BANK_ACCOUNTS':
		return {
			...state,
			isFetchingUserAccounts: true,
			userAccounts: [],
			userAccountsTotal: 0,
		};
	case 'GET_USER_BANK_ACCOUNTS_FAIL':
		return {
			...state,
			isFetchingUserAccounts: false,
		};
	case 'GET_USER_BANK_ACCOUNTS_SUCCESS':
		return {
			...state,
			isFetchingUserAccounts: false,
			userAccounts: action.payload.data,
			userAccountsTotal: parseInt(action.payload.headers['total-count'], 10),
		};
	case 'GET_ACCOUNT_BANK_ACCOUNTS':
		return {
			...state,
			isFetchingAccountAccounts: true,
			accountAccounts: [],
			accountAccountsTotal: 0,
		};
	case 'GET_ACCOUNT_BANK_ACCOUNTS_FAIL':
		return {
			...state,
			isFetchingAccountAccounts: false,
		};
	case 'GET_ACCOUNT_BANK_ACCOUNTS_SUCCESS':
		return {
			...state,
			isFetchingAccountAccounts: false,
			accountAccounts: action.payload.data,
			accountAccountsTotal: parseInt(action.payload.headers['total-count'], 10),
		};
	case 'CREATE_CLIENT_BANK_ACCOUNT_SUCCESS':
		return {
			...state,
			accountAccounts: [...state.accountAccounts, action.payload.data],
		};
	case 'GET_BANK_PAYMENT':
		return {
			...state,
			isFetchingBankPayment: true,
			error: false,
			bankPayment: {},
		};
	case 'GET_BANK_PAYMENT_SUCCESS':
		return {
			...state,
			isFetchingBankPayment: false,
			bankPayment: action.payload.data,
		};
	case 'GET_BANK_PAYMENT_FAIL':
		return {
			...state,
			isFetchingBankPayment: false,
			error: action.error,
		};
	default:
		return state;
	}
}
