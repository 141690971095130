// @flow

import React, { Component } from 'react';
import ReactJson from 'react-json-view';
import moment from 'moment';
import { connect } from 'react-redux';
//= import components
import CPButton from '../../../components/UiElements/Button';
import DataCard from '../../../components/UiElements/CustomCards/DataCard';
import Carousel from '../../../components/UiElements/Carousel';
import Modal from '../../../components/UiElements/Modal';
import Tag from '../../../components/UiElements/Tag';
import Row from '../../../components/UiElements/Layout/Row';
import Col from '../../../components/UiElements/Layout/Col';
import Spin from '../../../components/UiElements/Spin';
import Select from '../../../components/UiElements/Select';
import UserNoCkecks from '../../../components/UiElements/Illustrations/UserNoCkecks';
//= import actions
import { getKycReports } from '../../../modules/actions/UsersActions';
import { getProvidersInstances } from '../../../modules/actions/ProviderActions';
//= import helpers
import StatusHelpers from '../../../lib/helpers/statusHelpers';
//= import types
import type { KycReports, Documents } from '../../../modules/reducers/UsersReducer';
import type { ProviderInstance } from '../../../modules/reducers/ProviderReducer';
import type { State } from '../../../modules/types/FlowTypes';
//= import styles
import styles from '../assets/user.module.scss';

type Props = {
	kycReports: KycReports,
	documents: Documents,
	getKycReports: (string, number, string) => void,
	getProvidersInstances: (string) => void,
	status: string,
	name: string,
	userId: string,
	userAddress: {[string]: Object},
	applicationId: string,
	isFetchingKycReports: boolean,
	providers: Array<ProviderInstance>,
	handleDocumentUpload: Function,
	handlePerformCheck: Function,
	userRiskLevel: string,
	setUserRiskLevel: Function,
}
type LocalState = {
	responseOpen: number,
	visible: boolean,
	showInModal: Documents,
	isIdentity: boolean,
	rawResponse: Object,
	reportType: string,

}

function SamplePrevArrow(props: Object) {
	const { onClick } = props;
	return (
		<i className={`material-icons ${styles.left}`} role="presentation" onClick={onClick}>keyboard_arrow_left</i>

	);
}
function SampleNextArrow(props: Object) {
	const { onClick } = props;
	return (
		<i className={`material-icons ${styles.right}`} role="presentation" onClick={onClick}>keyboard_arrow_right</i>
	);
}

class KYC extends Component<Props, LocalState> {
	state = {
		responseOpen: 0,
		visible: false,
		showInModal: [],
		isIdentity: false,
		rawResponse: null,
		reportType: '',
	}

	componentDidMount() {
		const {
			userId,
			applicationId,
			providers,
			getKycReports: getKycReportsAction,
			getProvidersInstances: getProvidersInstancesAction,
		} = this.props;
		if (!providers.length) {
			getProvidersInstancesAction(applicationId);
		}
		getKycReportsAction(applicationId, 1, `userId=${userId}`);
	}

	openResponse = (index: number, rawResponse: Object, reportType: string) => {
		this.setState({
			responseOpen: index,
			rawResponse,
			reportType,
		});
	}

	showModal = (document: Documents) => {
		this.setState({
			visible: true,
			showInModal: document,
			isIdentity: false,
		});
	}

	showIdentityDocs = (document: Documents) => {
		this.setState({
			visible: true,
			showInModal: document,
			isIdentity: true,
		});
	}

	handleCancel = () => {
		this.setState({
			visible: false,
		});
	}

	getProviderName = (id) => {
		const { providers } = this.props;
		const instance = providers.find((el) => el.id === id);
		return instance?.provider.displayName;
	}

	handleRiskChange = (value) => {
		const { setUserRiskLevel } = this.props;
		setUserRiskLevel(value);
	}

	render() {
		const {
			kycReports,
			documents,
			status,
			name,
			isFetchingKycReports,
			handleDocumentUpload,
			handlePerformCheck,
			userAddress,
			userRiskLevel,
		} = this.props;
		const {
			rawResponse,
			reportType,
		} = this.state;
		const { isIdentity } = this.state;

		const reportTypes = {
			IDENTITY: 'identity',
			FACIAL_SIMILARITY: 'facialSimilarity',
			DOCUMENT: 'document',
			PEPS_AND_SANCTIONS: 'pepsAndSanctions',
			PROOF_OF_ADDRESS: 'proofOfAddress',
		};
		const kycStatus = {
			COMPLETED: 'completed',
			PENDING: 'pending',
			REJECTED: 'rejected',
			VERIFIED: 'verified',
		};
		const proofOfAddressDocuments = documents
			.filter((el) => StatusHelpers.documentTypes.includes(el.documentType));
		const identityDocuments = documents
			.filter((el) => !StatusHelpers.documentTypes.includes(el.documentType))
			.map((document) => document?.name)
			.map((documentName) => documents.find((el) => el.name === documentName));
		const onfidoDocuments = documents
			.filter((document) => StatusHelpers.onfidoDocumentTypes.includes(document.documentType));
		const {
			responseOpen, visible, showInModal,
		} = this.state;
		const mapData = kycReports.filter((el) => el.reportType !== reportTypes.PROOF_OF_ADDRESS)
			.map((part) => {
				let cardData = [{
					title: part.reportType,
					tag: part.reportData?.rawResponse?.reports?.[0]?.status || part.status,
					data: {
						provider: this.getProviderName(part.providerInstanceId) || '-',
						result: part.status === kycStatus.COMPLETED
							? (
								<Tag
									status={part.reportType === reportTypes.PEPS_AND_SANCTIONS
										? part?.reportData?.content.data.match_status
										: part?.reportData?.result
											|| part.reportData?.rawResponse?.result
											|| part.reportData?.rawResponse?.reports?.[0]?.result}
								/>
							)
							: '-',
						dateOfCheck: moment(part.createdAt).format('YYYY-MM-DD HH:mm:ss'),
					},
					rawResponse: part.reportData,
				}];

				if (part.reportType === reportTypes.DOCUMENT) {
					const providerName = this.getProviderName(part.providerInstanceId);
					cardData = [{
						title: part.reportType,
						tag: part.reportData?.rawResponse?.reports?.[0]?.status || part.status,
						data: {
							provider: providerName || '-',
							result: part.status === kycStatus.COMPLETED
								? (
									<Tag
										status={part.reportType === reportTypes.PEPS_AND_SANCTIONS
											? part?.reportData?.content.data.match_status
											: part?.reportData?.result
												|| part.reportData?.rawResponse?.result
												|| part.reportData?.rawResponse?.reports?.[0]?.result}
									/>
								)
								: '-',
							dateOfCheck: moment(part.createdAt).format('YYYY-MM-DD HH:mm:ss'),
						},
						rawResponse: part.reportData,
					}];

					if (providerName?.toLowerCase() === 'onfido') {
						const photos = {
							title: 'attached documents',
							photos: onfidoDocuments,
							photoAction: this.showModal,
						};

						cardData = [...cardData, photos];
					}
				}
				if (part.reportType === reportTypes.IDENTITY) {
					cardData = [{
						title: part.reportType,
						tag: part.status,
						data: {
							provider: this.getProviderName(part.providerInstanceId) || '-',
							result: part.status === kycStatus.COMPLETED
								? (
									<Tag status={part?.reportData?.result
										|| part.reportData?.rawResponse?.result
										|| part.reportData?.reports?.[0]?.result}
									/>
								)
								: '-',
							dateOfCheck: moment(part.createdAt).format('YYYY-MM-DD HH:mm:ss'),
						},
					}];
				}
				if (part.reportType === reportTypes.FACIAL_SIMILARITY) {
					const facialSimilarityDocs = identityDocuments.filter((document) => document.documentType === 'identity');
					cardData = [{
						title: part.reportType,
						tag: part.status || part?.reportData?.status,
						data: {
							provider: this.getProviderName(part.providerInstanceId) || '-',
							result: <Tag status={part?.reportData?.result
									|| part.reportData?.rawResponse?.result
									|| part.reportData?.reports?.[0]?.result}
							/>,
							dateOfCheck: moment(part.createdAt).format('YYYY-MM-DD HH:mm:ss'),
						},
					}];
					if (facialSimilarityDocs.length) {
						cardData = [...cardData, {
							title: 'attached documents',
							documents: facialSimilarityDocs,
							isIdentity: true,
							photoAction: this.showIdentityDocs,
						}];
					}
				}
				return { ...part, cardData };
			});
		const addressCheck = kycReports.filter((el) => el.reportType === reportTypes.PROOF_OF_ADDRESS);
		const addressData = addressCheck.length
			? addressCheck.map((part) => ({
				...part,
				cardData: [
					{
						title: part.reportType,
						tag: part.status,
						data: {
							country: userAddress?.country?.name || '-',
							postalCode: userAddress?.zipCode || '-',
							residentialCity: userAddress?.city || '-',
							address: `${userAddress?.street || '-'} ${userAddress?.streetNumber || ''}`,
							addressLine2: userAddress?.addressLine || '-',
						},
					}, {
						data: {
							provider: this.getProviderName(part.providerInstanceId) || '-',
							status: part.status === kycStatus.COMPLETED
								? (
									<Tag
										status={part?.reportData?.result || part.reportData?.rawResponse?.result}
									/>
								)
								: '-',
							dateOfCheck: moment(part.createdAt).format('YYYY-MM-DD HH:mm:ss'),
						},
					}, {
						title: 'attached documents',
						photos: proofOfAddressDocuments,
						photoAction: this.showModal,
					}, {
						button: {
							text: part.status === kycStatus.PENDING ? 'Perform KYC Check' : 'Upload document',
							icon: part.status === kycStatus.PENDING ? 'Checkmark' : 'Upload',
							action: part.status === kycStatus.PENDING ? () => handlePerformCheck(part) : handleDocumentUpload,
						},
					},
				],
			}))
			: [{
				cardData: [
					{
						title: 'Proof of Address',
						data: {
							country: userAddress?.country?.name || '-',
							postalCode: userAddress?.zipCode || '-',
							residentialCity: userAddress?.city || '-',
							address: `${userAddress?.street || '-'} ${userAddress?.streetNumber || ''}`,
							addressLine2: userAddress?.addressLine || '-',
						},
					}, {
						title: 'attached documents',
						photos: proofOfAddressDocuments,
						photoAction: this.showModal,
					}, {
						button: {
							text: 'Upload document',
							icon: 'Upload',
							action: handleDocumentUpload,
						},
					},
				],
			}];

		const finalData = [...mapData, addressData[0]];

		const { Option } = Select;

		const reactJsonHint = { hint: "Click one of the 'Raw response' buttons on the left to see the KYC results for the associated check." };

		return (
			<>
				{!isFetchingKycReports
					? (
						<div>
							<Row>
								<Col span={10} className={styles.kycCol}>
									<div className={styles.riskLevel}>
										<h4>
											Client Risk Level
										</h4>
										<Select
											showSearch
											placeholder="Please select Risk Level"
											defaultValue={userRiskLevel}
											onChange={this.handleRiskChange}
										>
											{StatusHelpers.riskLevels.map((el) => (
												<Option
													value={el.id}
													key={el.id}
												>
													{el.name}
												</Option>
											))}
										</Select>
									</div>
								</Col>
							</Row>
							<Row className="row__no-wrap">
								<Col span={10} className={styles.kycCol}>
									{finalData.map((el, index) => (
										<div>
											<DataCard parts={el.cardData} />
											{el?.reportData
											&& (
												<div className={styles.responseButton}>
													<span
														className={responseOpen === index
															? styles.active
															: styles.inactive}
													>
														<CPButton
															ghost
															action={() => this.openResponse(index, el?.reportData, el?.reportType)}
															text="raw response"
														/>
													</span>
												</div>
											)}
										</div>
									))}
								</Col>
								<Col span={14} className={styles.resCol}>
									{kycReports.length
										? (
											<div className={styles.response}>
												<h4>{`Raw Response: ${reportType || ''}`}</h4>
												<ReactJson
													src={rawResponse || reactJsonHint}
													theme="shapeshifter:inverted"
													enableClipboard={false}
													name="reportData"
													style={{
														backgroundColor: '#F0F6FA',
														padding: '16px',
													}}
												/>
											</div>
										) : (
											<div className="empty-state">
												<UserNoCkecks />
												<p>
													Checks are not yet performed for this client.
													You can still Verify or Reject the client manually,
													however, it is recommended to perform verification
													checks to complete verification.
												</p>
											</div>
										)}
								</Col>
							</Row>
						</div>
					)
					: <Spin spinning={isFetchingKycReports} />}
				{status === kycStatus.VERIFIED && <p className={styles.verifiedMsg}>{`User ${name} has been verified`}</p>}
				{status === kycStatus.REJECTED && <p className={styles.rejectedMsg}>{`User ${name} has been rejected`}</p>}
				{status !== kycStatus.VERIFIED && status !== kycStatus.REJECTED && <p className={styles.infoMsg}>{`${name} status is ${status ? status.toUpperCase() : '-'}`}</p>}
				<Modal
					visible={visible}
					onCancel={this.handleCancel}
					footer={null}
					width={900}
				>
					<div
						style={{
							backgroundColor: '#000', marginTop: '24px', height: '564px', width: '900',
						}}
					>
						<Carousel
							arrows
							nextArrow={<SampleNextArrow />}
							prevArrow={<SamplePrevArrow />}
							className={styles.carousel}
						>
							{showInModal.map((document) => (
								isIdentity
									? (document.files.map((file) => (
										file.contentType === 'application/pdf' ? (
											<iframe
												title="IdentityDocuments"
												key={file.id}
												src={`${file.url}#toolbar=0&navpanes=0&scrollbar=0`}
												alt="document"
												height="100%"
												width="100%"
												frameBorder="0"
											/>
										) : null
									)))
									: (document.files.map((file) => (
										file.contentType === 'application/pdf' ? (
											<iframe
												title="proofOfAddressDocs"
												key={file.id}
												src={`${file.url}#toolbar=0&navpanes=0&scrollbar=0`}
												alt="proof of address"
												height="100%"
												width="100%"
												frameBorder="0"
											/>
										) : (
											<img
												key={file.id}
												src={file.url}
												alt="proof of address document"
											/>
										)
									)))))}
						</Carousel>
					</div>
				</Modal>
			</>
		);
	}
}

const mapStateToProps = (state: State) => ({
	kycReports: state.users.kycReports,
	isFetchingKycReports: state.users.isFetchingKycReports,
	providers: state.providers.providersInstances,
});

const mapDispatchToProps = {
	getKycReports,
	getProvidersInstances,
};

export default connect(mapStateToProps, mapDispatchToProps)(KYC);
