// @flow
import React from 'react';
import Form from '../../../components/UiElements/Form';
import Input from '../../../components/UiElements/Input';
import ErrorMessage from '../../../components/UiElements/ErrorMessage';
import CPButton from '../../../components/UiElements/Button';

type Props = {
	form: *,
	visible: boolean,
	setApiKeyName: (apiKeyName: object)=> void,
	closeModal: ()=> void,
	openGenerated: ()=> void,
}

export type LocalState = {
	errorMessage: string,
}

class NameApiKeyForm extends React.Component<Props, LocalState> {
	state = {
		errorMessage: '',
	};

	submit = (e) => {
		e.preventDefault();
		const { setApiKeyName, form, closeModal, openGenerated,} = this.props;
		form.validateFields((err: Array<Object>, values) => {
			if (err) {
				const formError = (Object.values(err).map((error: Object) => error.errors[0].message));
				this.setState({
					errorMessage: formError.join(', '),
				});
			} else {
				this.setState({
					errorMessage: '',
				});
				const data = {
					...values,
				};
				openGenerated();
				setApiKeyName(data.apiKeyName);
				closeModal();
			}
		});
	}

	render() {
		const {
			visible,
			form: {
				getFieldDecorator, isFieldsTouched,
			},
		} = this.props;

		const { errorMessage } = this.state;

		const fixedLayout = {
			labelCol: {
				xs: { span: 8 },
			},
			wrapperCol: {
				xs: { span: 12 },
			},
		};

		const FormItem = Form.Item;

		return visible && (
			<Form onSubmit={this.submit} layout="horizontal">
				<FormItem label="NAME YOUR API KEY" {...fixedLayout} help="">
					{getFieldDecorator(
						'apiKeyName', {
							rules: [
								{ required: true, message: 'API key name is required!' },
								{ pattern: /^\S+$/, message: 'No spaces, please.' },
							],
						},
					)(
						<Input
							placeholder="One word — no spaces"
						/>,
					)}
					{errorMessage && (
						<ErrorMessage message={errorMessage} />
					)}
				</FormItem>
				<FormItem>
					<CPButton type="primary" action={this.submit} disabled={!isFieldsTouched()} text="generate API key" />
				</FormItem>
			</Form>
		);
	}
}

const NameApiKeyModal = Form.create()(NameApiKeyForm);
export default NameApiKeyModal;
