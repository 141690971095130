// @flow

import React from 'react';
import { connect } from 'react-redux';
import differenceBy from 'lodash/differenceBy';
import queryString from 'query-string';
//= import components
import InputNumber from '../../../components/UiElements/InputNumber';
import Form from '../../../components/UiElements/Form';
import CPButton from '../../../components/UiElements/Button';
import Select from '../../../components/UiElements/Select';
import Input from '../../../components/UiElements/Input';
import ErrorMessage from '../../../components/UiElements/ErrorMessage';
// = import actions
import { getAppInstruments } from '../../../modules/actions/InstrumentAction';
// = import helpers
import PriceHelpers from '../../../lib/helpers/priceHelpers';
// = import types
import type { Wallet } from '../../../modules/reducers/WalletReducer';
import type { Account } from '../../../modules/reducers/AccountsReducer';
import type { Instrument } from '../../../modules/reducers/InstrumentReducer';
import type { FormProps } from '../../../modules/types/FormTypes';
//= import styles
import styles from '../assets/user.module.scss';

type Values = {
	netFee: number,
	amount: number,
	wallet: string,
}
type Value = {
	wallet: ?Wallet,
	instrumentId?: string,
	fee?: string,
	amount: string,
	values: Values,
}
type InstrumentResponse = {
	payload: {
		data: Array<Instrument>,
	}
}
type Props = {
	form: FormProps,
	submitChanges: (Value) => void,
	handleCancelClick: () => void,
	wallets: Array<Wallet>,
	subtitle: string,
	type: string,
	accounts: Array<Account>,
	getAppInstruments: (string, string, number, string) => Promise<InstrumentResponse>,
	instrument: Instrument,
	applicationId: string,
	place: string,
	fiatInstruments: Array<Instrument>,
}
type LocalState = {
	errorMessage: string,
	selectedWallet: Wallet,
	selectedInstrument: Instrument,
	symbol: string,
	precision: number,
	amount: number,
}

const setSelected = (type, wallets) => {
	const transferWallets = wallets.filter((el) => el.instrument.type === 'fiat' || el.instrument.type === 'crypto');
	const cryptoWallets = wallets.filter((el) => el.instrument.type === 'crypto');
	if (type === 'internalTransfer') {
		return transferWallets.length === 1 ? transferWallets[0] : {};
	} if (type === 'cryptoPayout') {
		return cryptoWallets.length === 1 ? cryptoWallets[0] : {};
	}
	return wallets.length === 1 ? wallets[0] : {};
};

class EditForm extends React.Component<Props, LocalState> {
	state = {
		errorMessage: '',
		selectedWallet: setSelected(this.props.type, this.props.wallets),
		selectedInstrument: {},
		symbol: setSelected(this.props.type, this.props.wallets)?.instrument?.formatting?.grapheme
		|| setSelected(this.props.type, this.props.wallets).instrumentId,
		precision: setSelected(this.props.type, this.props.wallets)?.instrument?.precision || 8,
		amount: 0,
	}

	componentDidMount() {
		const {
			getAppInstruments: getAppInstrumentsAction,
			applicationId,
		} = this.props;
		if (applicationId) {
			const filterOptions: string = queryString.stringify({ type: 'fiat' });
			getAppInstrumentsAction(applicationId, 'numbered-pages', 0, filterOptions);
		}
	}

	handleReset = () => {
		this.props.form.resetFields();
		this.setState({
			selectedWallet: setSelected(this.props.type, this.props.wallets),
			errorMessage: '',
			symbol: (this.props.wallets.length === 1) ? this.props.wallets[0].instrument?.formatting?.grapheme : '',
			selectedInstrument: {},
			amount: 0,
		});
	}

	selectWallet = (value: string) => {
		const { fiatInstruments } = this.props;
		const instrument = fiatInstruments.find((el) => el.instrumentId === value);
		if (value) {
			if (instrument) {
				this.setState({
					selectedInstrument: instrument,
					precision: instrument.precision,
					symbol: instrument.formatting.grapheme,
				});
			} else {
				const selectedWallet = this.props.wallets.find((wallet) => wallet.id === value);
				this.setState({
					selectedWallet,
					selectedInstrument: {},
					precision: selectedWallet?.instrument?.precision || 8,
					symbol: selectedWallet?.instrument?.formatting?.grapheme || '',
				});
			}
		} else {
			this.setState({
				selectedInstrument: {},
				symbol: '',
				precision: 8,
			});
		}
	}

	onAmountChange = (value: number) => {
		this.setState({
			amount: value,
		});
	}

	validateAmount = (rule, value, callback) => {
		const { selectedWallet, precision } = this.state;
		const { type } = this.props;
		if (!value) {
			callback("Amount can't be left empty");
		}
		if (type !== 'credit' && value > selectedWallet.available) {
			callback('The Amount must be less than or equal to the Source Wallet Balance');
		}
		if (parseFloat(value.toFixed(precision)) !== value) {
			callback('Precision must be less than or equal to the Source Wallet Precision');
		}
		callback();
	}

	validateFee = (rule, value, callback) => {
		const { amount, precision } = this.state;
		if (value !== 0 && !value) {
			callback("Fee can't be left empty");
		}
		if (value < 0 || value > amount) {
			callback('Fee must be smaller than Amount');
		}
		if (parseFloat(value.toFixed(precision)) !== value) {
			callback('Precision must be less than or equal to the Source Wallet Precision');
		}
		callback();
	}

	submit = (e) => {
		e.preventDefault();
		const { form, submitChanges } = this.props;
		const {
			selectedWallet, selectedInstrument,
		} = this.state;
		form.validateFields((err: Object, values: Values) => {
			if (err) {
				const formError = (Object.values(err).map((error: Object) => error.errors[0].message));
				this.setState({
					errorMessage: formError.join(', '),
				});
			} else {
				const data = {
					values,
					wallet: selectedWallet,
					instrumentId: selectedInstrument.instrumentId,
					amount: PriceHelpers.formatAmount(
						values.amount.toString(),
						selectedWallet.instrumentId || selectedInstrument.instrumentId,
					),
					fee: values.netFee === undefined
						? values.netFee
						: PriceHelpers.formatAmount(
							values.netFee.toString(),
							selectedWallet.instrumentId || selectedInstrument.instrumentId,
						),
				}; 
				submitChanges(data);
				this.setState({
					errorMessage: '',
				});
			}
		});
	}

	render() {
		const {
			form: { getFieldDecorator, isFieldTouched },
			handleCancelClick,
			subtitle,
			wallets,
			type,
			accounts,
			place,
			fiatInstruments,
		} = this.props;
		const {
			errorMessage,
			selectedWallet,
			symbol,
		} = this.state;

		const availableFiatInstruments: Array<Instrument> = differenceBy(fiatInstruments, wallets, 'instrumentId');
		const transferWallets: Array<Wallet> = wallets.filter((el) => el.instrument.type === 'fiat' || el.instrument.type === 'crypto');
		const cryptoWallets: Array<Wallet> = wallets.filter((el) => el.instrument.type === 'crypto');

		const { Option } = Select;
		const { TextArea } = Input;
		const FormItem = Form.Item;
		const formItemLayout = {
			labelCol: {
				xs: { span: 10 },
			},
			wrapperCol: {
				xs: { span: 10 },
				offset: 4,
			},
			labelAlign: 'left',
		};
		const walletLayout = {
			labelCol: {
				xs: { span: 10 },
			},
			wrapperCol: {
				xs: { span: 14 },
			},
			labelAlign: 'left',
		};
		return (
			<Form onSubmit={this.submit} layout="horizontal" hideRequiredMark labelalign="left">
				<div className="subtitle">
					{subtitle}
				</div>
				{type === 'internalTransfer' && (
					<FormItem label="FROM WALLET" {...walletLayout}>
						{getFieldDecorator('wallet', {
							rules: [{ required: true, message: "Wallet can't be left empty" }],
							initialValue: selectedWallet.id,
						})(
							<Select
								allowClear
								showSearch
								placeholder="Select Wallet"
								onChange={this.selectWallet}
								disabled={transferWallets.length === 1}
							>
								{transferWallets.map((wallet) => (
									<Option value={wallet.id} key={wallet.id}>
										<div>
											{`${wallet.instrumentId} Wallet `}
											{accounts && (
												<span className={styles.accountName}>
													{`(${accounts.filter((account) => account.id === wallet.accountId)[0].name})`}
												</span>
											)}
										</div>
										<div className={styles.walletId}>{`ID ${wallet.id}`}</div>
									</Option>
								))}
							</Select>,
						)}
					</FormItem>
				)}
				{type === 'internalTransfer' && (
					<FormItem label="TO WALLET" {...walletLayout}>
						{getFieldDecorator('destinationWalletId', {
							rules: [{ required: true, message: "Wallet can't be left empty" }],
						})(
							<Input placeholder="Enter Destination Wallet Id" />,
						)}
					</FormItem>
				)}
				{type === 'cryptoPayout' && (
					<FormItem label="WALLET" {...walletLayout}>
						{getFieldDecorator('wallet', {
							rules: [{ required: true, message: "Wallet can't be left empty" }],
							initialValue: selectedWallet.id,
						})(
							<Select
								allowClear
								showSearch
								placeholder="Select Wallet"
								onChange={this.selectWallet}
								disabled={cryptoWallets.length === 1}
							>
								{cryptoWallets.map((wallet) => (
									<Option value={wallet.id} key={wallet.id}>
										<div>
											{`${wallet.instrumentId} Wallet `}
											{accounts && (
												<span className={styles.accountName}>
													{`(${accounts.filter((account) => account.id === wallet.accountId)[0].name})`}
												</span>
											)}
										</div>
										<div className={styles.walletId}>{`ID ${wallet.id}`}</div>
									</Option>
								))}
							</Select>,
						)}
					</FormItem>
				)}
				{(type === 'credit' || type === 'debit') && (
					<FormItem label="WALLET" {...walletLayout}>
						{getFieldDecorator('wallet', {
							rules: [{ required: true, message: "Wallet can't be left empty" }],
							initialValue: selectedWallet.id,
						})(
							<Select
								allowClear
								showSearch
								placeholder={`Select Wallet to ${type}`}
								onChange={this.selectWallet}
								disabled={!(place === 'client' && type === 'credit') && (wallets.length === 1)}
							>
								{!!wallets.length
								&& (
									<Option key="wallets" disabled>
										<span className="bold">WALLETS</span>
									</Option>
								)}
								{wallets.map((wallet) => (
									<Option value={wallet.id} key={wallet.id}>
										<div>
											{`${wallet.instrumentId} Wallet `}
											{accounts && (
												<span className={styles.accountName}>
													{`(${accounts.find((account) => account.id === wallet.accountId)?.name || 'Account'})`}
												</span>
											)}
										</div>
										<div className={styles.walletId}>{`ID ${wallet.id}`}</div>
									</Option>
								))}
								{!!availableFiatInstruments.length && (type === 'credit') && (place === 'client')
								&& (
									<Option key="creditFiat" disabled>
										<span className="bold">CREDIT AND CREATE WALLET</span>
									</Option>
								)}
								{type === 'credit' && (place === 'client') && availableFiatInstruments.map((instrument) => (
									<Option value={instrument.instrumentId} key={instrument.id}>
										<div>
											{`${instrument.instrumentId} Wallet `}
										</div>
									</Option>
								))}
							</Select>,
						)}
					</FormItem>
				)}
				<FormItem label="AMOUNT" {...formItemLayout}>
					{getFieldDecorator('amount', {
						rules: [
							{ validator: this.validateAmount },
						],
					})(
						<InputNumber
							placeholder="0.00"
							symbol={symbol}
							onChange={this.onAmountChange}
							className={styles.input}
						/>,
					)}
				</FormItem>
				{ (type === 'credit' || type === 'debit') && (
					<FormItem label="NET FEE" {...formItemLayout}>
						{getFieldDecorator('netFee', {
							rules: [
								{ validator: this.validateFee },
							],
						})(
							<InputNumber
								placeholder="0.00"
								symbol={symbol}
								className={styles.input}
							/>,
						)}
					</FormItem>
				)}
				{(type === 'internalTransfer' || type === 'credit' || type === 'debit') && (
					<FormItem label="MESSAGE" {...walletLayout}>
						{getFieldDecorator('message')(
							<TextArea maxLength={400} placeholder="Enter a message" />,
						)}
					</FormItem>
				)}
				{type === 'cryptoPayout' && (
					<FormItem label="DESTINATION ADDRESS" {...walletLayout}>
						{getFieldDecorator('destinationAddress', {
							rules: [{ required: true, message: "Destination Address can't be left empty" }],
						})(
							<Input placeholder="Enter a Destination Address" />,
						)}
					</FormItem>
				)}
				{errorMessage && (
					<ErrorMessage message={errorMessage} />
				)}
				<div className="form-buttons">
					<FormItem>
						<CPButton ghost action={handleCancelClick} text="cancel" />
					</FormItem>
					<FormItem>
						<CPButton type="primary" action={this.submit} disabled={!isFieldTouched('amount')} text={type === 'internalTransfer' ? 'internal transfer' : `${type} ${place}`} />
					</FormItem>
				</div>
			</Form>
		);
	}
}

const CreditDebitForm = Form.create()(EditForm);

const mapStateToProps = (state) => ({
	fiatInstruments: state.instrument.instruments,
});

const mapDispatchToProps = {
	getAppInstruments,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditDebitForm);
