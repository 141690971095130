// @flow

import React, { useMemo, useEffect, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

//= import components
import { notification } from 'antd';
import Title from '../../../components/UiElements/Title';
import CustomTable from '../../../components/UiElements/CustomTable/index';
import { getApiKeys, revokeApiKey } from '../../../modules/actions/ApiKeyActions';
import Spin from '../../../components/UiElements/Spin/index';
import GenerateApiKeyModal from '../components/GenerateApiKeyModal';
import NameApiKeyModal from '../components/NameApiKeyModal';
import Modal from '../../../components/UiElements/Modal/index';
import CPButton from '../../../components/UiElements/Button/index';
import DeleteModal from '../../../components/UiElements/Modal/DeleteModal';

type ApiKey = {
	id: string,
	createdAt: string,
	token: string,
	hasBackend: string,
}

type Props = {
	match: {
		params: {
			applicationId: string,
		}
	},
	history: {
		push: (Object) => void,
		goBack: () => void,
	},
	getApiKeys: (string) => Promise<Object>,
	revokeApiKey: (string, string) => Promise<Object>,
	apiKeys: Array<ApiKey>,
	isFetchingApiKeys: boolean,
};

const REVOKE_STYLE = { color: 'red', cursor: 'pointer' };

const ApiKeys = ({
	apiKeys,
	isFetchingApiKeys,
	getApiKeys: getApiKeysAction,
	revokeApiKey: revokeApiKeyAction,
	match: { params: { applicationId } },
}: Props) => {
	const [visibleModal, setVisibleModal] = useState(false);
	const openModal = () => setVisibleModal(true);
	const closeModal = () => setVisibleModal(false);
	const [apiKeyName, setApiKeyName] = useState({});
	const [generatedModal, setGeneratedModal] = useState(false);
	const openGenerated = () => setGeneratedModal(true);
	const closeGenerated = () => setGeneratedModal(false);
	const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
	const history = useHistory();
	const [deleteRecord, setDeleteRecord] = useState(null);
	const openDeleteModal = (record) => {
		setDeleteRecord(record);
		setVisibleDeleteModal(true);
	};
	const closeDeleteModal = () => {
		setDeleteRecord(null);
		setVisibleDeleteModal(false);
	};

	const handleRevokeApiKey = () => {
		if (deleteRecord !== null) {
			revokeApiKeyAction(applicationId, deleteRecord.id).then(() => {
				notification.success({
					message: 'Success',
					description: 'API Key successfully revoked',
					duration: 5,
					placement: 'bottomLeft',
				});
				setDeleteRecord(null);
				closeDeleteModal();
				getApiKeysAction(applicationId);
			});
		}
	};

	const columns = useMemo(() => [
		{
			title: 'created at',
			dataIndex: 'createdAt',
			key: 'createdAt',
			width: 208,
			render: (text: string) => (
				<div className="capitalize">
					{moment(text).format('YYYY-MM-DD')}
				</div>
			),
		},
		{
			title: 'Key nickname',
			dataIndex: ['owner', 'userInfo', 'firstName'],
			key: 'owner',
			width: 160,
			render: (text: string) => (
				<div>
					{text}
				</div>
			),
		},
		{
			title: 'backend',
			dataIndex: 'hasBackend',
			key: 'hasBackend',
			width: 160,
			render: (text: boolean) => (
				<div>
					{text ? 'Yes' : 'No'}
				</div>
			),
		},
		{
			title: 'token',
			dataIndex: 'token',
			key: 'token',
			width: 160,
			render: (text: string) => (
				<div>{text}</div>
			),
		}, {
			title: 'Actions',
			dataIndex: '',
			key: 'action',
			width: 96,
			render: (record: ApiKey) => record.hasBackend && (
				<div style={REVOKE_STYLE}>Revoke</div>
			),
			onCell: (record: ApiKey) => ({
				onClick: (e) => {
					e.stopPropagation();
					if (record.hasBackend) openDeleteModal(record);
				},
			}),
		},
		// eslint-disable-next-line
	], [applicationId]);

	useEffect(() => {
		getApiKeysAction(applicationId);
		// eslint-disable-next-line
	}, [applicationId]);

	return (
		<>
			<Title
				backAction={() => { history.goBack(); }}
				title="API Keys"
				buttons={[
					{
						text: 'Generate API Key',
						icon: 'Plus',
						action: openModal,
					},
				]}
			/>
			<div className="container">
				{!isFetchingApiKeys ? (
					<CustomTable
						columns={columns}
						data={apiKeys}
						total={apiKeys.length}
						currentPage={1}
						place="apiKeys"
						applicationId={applicationId}
						headerHeight={16}
						width={736}
					/>
				) : <Spin loadingh />}
			</div>
			<Modal
				title="Name Your API Key"
				visible={visibleModal}
				onCancel={closeModal}
				destroyOnClose
				footer={[
					<CPButton
						ghost
						key="cancel"
						action={closeModal}
						text="close"
					/>,
				]}
			>
				<NameApiKeyModal
					visible={visibleModal}
					setApiKeyName={setApiKeyName}
					closeModal={closeModal}
					openGenerated={openGenerated}
				/>
			</Modal>
			<Modal
				title="Generate API Key"
				visible={generatedModal}
				onCancel={closeGenerated}
				destroyOnClose
				footer={[
					<CPButton
						ghost
						key="cancel"
						action={closeGenerated}
						text="close"
					/>,
				]}
			>
				<GenerateApiKeyModal
					applicationId={applicationId}
					generateKey={generatedModal}
					apiKeyName={apiKeyName}
				/>
			</Modal>
			<DeleteModal
				okFunction={handleRevokeApiKey}
				cancelFunction={closeDeleteModal}
				visible={visibleDeleteModal}
				btnText="Revoke API Key"
				message="One you delete API key, you will not be able to use it anymore."
			/>
		</>
	);
};

const mapStateToProps = (state) => ({
	apiKeys: state.apiKey.apiKeys,
	isFetchingApiKeys: state.apiKey.isFetchingApiKeys,
});

const mapDispatchToProps = {
	getApiKeys,
	revokeApiKey,
};

export default connect(mapStateToProps, mapDispatchToProps)(ApiKeys);
